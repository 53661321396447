import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useMemo,
} from 'react'
import { NavLinkProps } from 'react-router-dom'

import { FvNavLink } from './FvNav'
import { Icon, IconProps } from './Icon'

type FvButtonTheme =
  | 'callout'
  | 'default'
  | 'plain'
  | 'primary'
  | 'secondary'
  | 'round'
  | 'underlined'

export type FvLinkButtonProps = Omit<
  ComponentPropsWithoutRef<'a'> &
    Partial<Pick<NavLinkProps, 'to' | 'end'>> &
    Partial<Pick<IconProps, 'icon' | 'transform'>> & {
      disabled?: boolean
      fw?: boolean
      iconClass?: string
      theme?: FvButtonTheme
    },
  'className'
> & {
  className?: string | ((props: { isActive: boolean }) => string)
}

export const FvLinkButton = forwardRef(
  (
    {
      disabled,
      onClick,
      href = '#',
      to,
      icon,
      children,
      iconClass,
      transform,
      fw,
      theme,
      ...props
    }: FvLinkButtonProps,
    ref?: ForwardedRef<HTMLAnchorElement>,
  ) => {
    let anchorClick = onClick

    if (href === '#') {
      anchorClick = e => {
        e.preventDefault()
        onClick && onClick(e)
      }
    }

    const elContents = (
      <>
        {icon && (
          <Icon
            className={clsx(iconClass, { 'fa-fw': fw })}
            {...{
              icon,
              transform,
            }}
          />
        )}
        {children && (
          <span className="items-center overflow-hidden">{children}</span>
        )}
      </>
    )

    const themeClass = useMemo(() => (theme ? themes[theme] : ''), [theme])

    const className = clsx(
      typeof props.className === 'string' ? props.className : '',
      themeClass,
      {
        'pointer-events-none': disabled,
      },
    )

    return to ? (
      <FvNavLink
        {...props}
        className={({ isActive }) =>
          clsx(
            `text-[#4d4d4d] [&>svg]:hover:text-[#333] whitespace-nowrap`,
            typeof props.className === 'string'
              ? className
              : props.className?.({ isActive }),
          )
        }
        onClick={onClick}
        to={to}
      >
        {elContents}
      </FvNavLink>
    ) : (
      <a
        {...props}
        className={` text-[#4d4d4d] whitespace-nowrap [&>svg]:hover:text-[#333] ${className}`}
        href={href}
        onClick={anchorClick}
        ref={ref}
        role="button"
      >
        {elContents}
      </a>
    )
  },
)

export type FvButtonProps = PropsWithChildren<
  ComponentPropsWithoutRef<'button'> & {
    icon?: IconProps['icon']
    loading?: boolean
    theme?: FvButtonTheme
    transform?: IconProps['transform']
    size?: IconProps['size']
    type?: 'button' | 'reset' | 'submit'
    iconClass?: string
    fwd?: boolean
    fw?: boolean
    activeHint?: boolean
  }
>

export const FvButton = forwardRef(
  (props: FvButtonProps, ref?: ForwardedRef<HTMLButtonElement>) => {
    const { theme, ...otherProps } = props

    return (
      <IconButton
        ref={ref}
        {...otherProps}
        theme={theme}
        className={clsx(
          'btn whitespace-nowrap leading-none hover:z-10',
          theme ? theme : 'text-[#4d4d4d]',

          props.className,
        )}
      />
    )
  },
)

const themes: Record<FvButtonTheme, string> = {
  default: `bg-white h-[2.8rem] px-[0.6rem] border border-[#b3b3b3] hover:border-[#999] relative [&>svg]:hover:text-[#333] outline-2 outline-dotted outline-transparent outline-offset-0 transition-all hover:outline-offset-2 hover:outline-fv-gray flex items-center`,

  callout: 'text-fv-blue hover:text-[#333]',

  plain: `bg-none border-none p-[0.6rem] [&>svg]:hover:text-[#333] h-[2.8rem]`,

  primary: `flex items-center bg-fv-blue text-[#f2f2f2] border border-fv-blue h-[2.8rem] px-[0.6rem] relative [&>svg]:text-[#fffde9] outline-2 outline-dotted outline-transparent outline-offset-0 transition-all hover:outline-offset-2 hover:outline-fv-blue-500 [&>svg]:hover:text-[#333]`,

  secondary: `bg-fv-blue-100 h-[2.8rem] relative px-[0.6rem] border border-fv-blue  relative [&>svg]:hover:text-[#333]`,

  underlined: `underline decoration-fv-blue underline-offset-4 hover:decoration-inherit`,

  round:
    'border-fv-blue bg-fv-blue-100 relative -left-2 flex h-4 w-4 items-center justify-center rounded-full border-2 p-4 [&>svg]:hover:text-[#333]',
} as const

export const IconButton = forwardRef(
  (
    {
      icon,
      type = 'button',
      className,
      children,
      theme,
      loading = false,
      onClick,
      transform,
      size,
      iconClass,
      fwd,
      fw,
      disabled,
      activeHint,

      ...props
    }: PropsWithChildren<FvButtonProps>,
    ref?: ForwardedRef<HTMLButtonElement>,
  ) => {
    const themeClass = useMemo(() => (theme ? themes[theme] : ''), [theme])

    return (
      <button
        ref={ref}
        className={clsx(
          themeClass,
          className,
          fwd &&
            'before:absolute before:right-[-8px] before:top-1/2 before:z-20 before:-translate-y-1/2 before:border-[8px] before:border-r-0 before:border-b-transparent before:border-t-transparent after:absolute after:right-[-9px] after:top-1/2 after:z-10 after:-translate-y-1/2 after:border-[8px] after:border-r-0 after:border-b-transparent  after:border-t-transparent',
          fwd &&
            theme === 'default' &&
            'before:border-l-white after:border-l-[#b3b3b3]',
          fwd &&
            theme === 'primary' &&
            'before:border-l-fv-blue after:border-l-fv-blue',
          fwd &&
            theme === 'secondary' &&
            'before:border-l-fv-blue-100 after:border-l-fv-blue',

          { 'opacity-50 [&>svg]:hover:text-inherit': disabled },
          !theme && '[&>svg]:hover:text-[#333]',
        )}
        type={type}
        onClick={e => !loading && !disabled && onClick?.(e)}
        disabled={disabled}
        {...props}
      >
        {loading && <Icon icon="spinner" transform={transform} />}
        {icon && !loading && (
          <Icon
            icon={icon}
            transform={transform}
            size={size}
            className={clsx(iconClass, { 'fa-fw': fw })}
          />
        )}
        {children && (
          <span
            className={clsx(
              'relative',
              !theme &&
                activeHint &&
                'inline-block after:absolute after:w-[.4rem] after:h-[.4rem] after:rounded-full after:bg-fv-orange after:-bottom-[.85rem] after:left-1/2',
            )}
          >
            {children}
          </span>
        )}
      </button>
    )
  },
)

export const isFvLinkButtonProps = (
  props: FvLinkButtonProps | FvButtonProps,
): props is FvLinkButtonProps => {
  const val = props as FvLinkButtonProps
  return !!(val.to || val.href)
}
