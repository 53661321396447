import dayjs from 'dayjs'
import defaultsDeep from 'lodash/defaultsDeep'
import isNil from 'lodash/isNil'
import {
  array,
  type BaseSchema,
  boolean,
  coerce,
  date,
  fallback,
  getFallbacks,
  literal,
  nullable,
  number,
  object,
  type ObjectEntries,
  omit,
  optional,
  type Output,
  pick,
  picklist,
  type PicklistOptions,
  required,
  string,
  transform,
  union,
  variant,
} from 'valibot'

import { allowEmptyStringRegex, emailRegex } from '../../core'
import {
  type ArrayValidators,
  type NumberValidators,
  type StringValidators,
  validatorMap,
} from '../validators'

export const vObject = <TEntries extends ObjectEntries>(
  entries: TEntries,
  errorMessage?: string,
) => object<TEntries>(entries, errorMessage)

export const vDate = date
export const vCoerce = coerce
export const vTransform = transform
export const vOmit = omit
export const vPick = pick
export const vOneOf = union
export const vRequired = required
export const vLiteral = literal
export const vVariant = variant
export const vBoolean = boolean

export const vBooleanDefault = (defaultValue: boolean) =>
  vCoerce(vBoolean(), v =>
    v === false || v?.toString().toLowerCase() === 'false'
      ? false
      : v === true || v?.toString().toLowerCase() === 'true'
        ? true
        : defaultValue,
  )
export const vArray = <T extends BaseSchema>(
  schema: T,
  validators?: ArrayValidators,
) => {
  return array<T>(
    schema,
    Object.entries(validators ?? {}).map(([k, v]) => validatorMap[k](v)),
  )
}

export const vCoercedDate = coerce(date(), v => {
  if (!v) {
    return v
  }

  return dayjs.utc(v as string).toDate()
})

export const vDefaults = <T extends BaseSchema>(
  schema: T,
  defaults: Partial<Output<T>>,
) => {
  return coerce(schema, v => defaultsDeep(v, defaults) as Output<T>)
}

export const vString = (
  validators?: StringValidators,
  errorMessage?: string,
) => {
  return coerce(
    string(errorMessage, [
      ...Object.entries(validators ?? {}).map(([k, v]) =>
        validatorMap[k](v, errorMessage),
      ),
    ]),
    v => {
      return isNil(v) ? v : String(v)
    },
  )
}

export const vObjectId = () => vString({ maxLength: 24, minLength: 24 })

export const vPicklist = <const TOptions extends PicklistOptions>(
  options: TOptions,
) => picklist(options, `Must be one of ${options.join(', ')}`)

export const vCoerceArray = <T extends BaseSchema>(schema: T) =>
  coerce(array(schema), value =>
    Array.isArray(value) ? value : !value ? [] : [value],
  )

export const vAddFields = <T extends BaseSchema, TFields>(
  schema: T,
  fields: TFields,
) => {
  return transform(schema, v => ({ ...v, ...fields }))
}

export const vEmail = transform(vString({ regex: emailRegex }), v =>
  v ? v.toLowerCase().trim() : v,
)

export const vEmailOrEmpty = vString({
  regex: allowEmptyStringRegex(true, emailRegex),
})

export const vNumber = (validators?: NumberValidators) => {
  return coerce(
    number(
      Object.entries(validators ?? {}).map(([k, v]) => validatorMap[k](v)),
    ),
    v => (v ? Number(v) : v),
  )
}
export const vOptional = <const TSchema extends BaseSchema>(wrapped: TSchema) =>
  optional(nullable(wrapped))

export const vFallback = fallback
export const createDefault = getFallbacks
export const vFallbackObj = <const TSchema extends BaseSchema>(
  wrapped: TSchema,
) => vFallback(wrapped, createDefault(wrapped))

export const vBooleanQuery = () =>
  vTransform(vOneOf([vBoolean(), vPicklist(['true', 'false'])]), v => {
    if (typeof v === 'string') return v.toLowerCase() === 'true'
    return v
  })
