import { vFallback, vNumber, vObject } from '../validation'

export const paginationSchema = vObject({
  skip: vNumber(),
  limit: vNumber(),
})

export const createPaginationSchema = (defaults: {
  skip?: number
  limit?: number
}) =>
  vObject({
    skip: vFallback(vNumber(), defaults.skip ?? 0),
    limit: vFallback(vNumber(), defaults.limit ?? 20),
  })
