import {
  vBooleanQuery,
  vFallback,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
  vTransform,
} from '../validation'
import { createPaginationSchema } from './paged'

export const opportunitySort = [
  'pickupDate',
  'bidExpirationDate',
  'lastMessageDate',
] as const
export type OpportunitySort = (typeof opportunitySort)[number]

export interface OpportunitySortBase {
  sortBy?: OpportunitySort
  sortDirection?: 1 | -1
}

export const opportunityStatuses = [
  'open',
  'offered',
  'declined',
  'lost',
  'awarded',
  'confirmed',
] as const
export type OpportunityStatus = (typeof opportunityStatuses)[number]

const MAX_LIMIT = 20

export const opportunityFilterSchema = vObject({
  ...createPaginationSchema({ limit: MAX_LIMIT }).entries,
  accountId: vOptional(vString()),
  status: vPicklist(opportunityStatuses),
  isArchived: vOptional(vBooleanQuery()),
  sortBy: vFallback(vPicklist(opportunitySort), 'pickupDate'),
  sortDirection: vFallback(
    vTransform(vPicklist([1, -1, '1', '-1']), v => Number(v) as 1 | -1),
    1,
  ),
})
export type OpportunityFilterDTO = vOutput<typeof opportunityFilterSchema>

export const opportunityBucketCountQuerySchema = vObject({
  accountId: vOptional(vString()),
})
export type OpportunityBucketCountDto = vOutput<
  typeof opportunityBucketCountQuerySchema
>

export type OpportunityBucketCountResponse = {
  awarded: number
  confirmed: number
  offered: number
  open: number
}
